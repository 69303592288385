.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  min-height: 400px;
}
.gDllUf{
  display: inherit !important;
}
.progress,.progress-bar{
  height: 20px !important;
}
